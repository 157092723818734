.admin-menu {
  background-color: white;
  border-radius: 15px;
}

.admin-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.rwd-table {
  margin: auto;
  min-width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}
td a {
  background: linear-gradient(264.28deg, #DEC7FF -38.2%, #5C27FE 103.12%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-decoration: none;
}


.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: 0.5em;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: 0.4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: 0.5em 1em;
}
@media screen and (max-width: 1024px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 1023px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: 0.25em 0.5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}

/* THE END OF THE IMPORTANT STUFF */

@-webkit-keyframes leftRight {
  0% {
    -webkit-transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(-10px);
  }
  75% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes leftRight {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 601px) {
  .rwd-table tr {
    background-image: linear-gradient(
      264.28deg,
      #dec7ff -38.2%,
      #5c27fe 103.12%
    );
    background-image: -moz-linear-gradient(
      left,
      #428bca 137px,
      #f5f9fc 1px,
      #f5f9fc 100%
    );
    background-image: -o-linear-gradient(
      left,
      #428bca 137px,
      #f5f9fc 1px,
      #f5f9fc 100%
    );
    background-image: -ms-linear-gradient(
      left,
      #428bca 137px,
      #f5f9fc 1px,
      #f5f9fc 100%
    );
    background-image: linear-gradient(
      left,
      #428bca 137px,
      #f5f9fc 1px,
      #f5f9fc 100%
    );
  }
  .rwd-table tr:nth-child(odd) {
    background-image: -webkit-linear-gradient(
      left,
      #428bca 137px,
      #ebf3f9 1px,
      #ebf3f9 100%
    );
    background-image: -moz-linear-gradient(
      left,
      #428bca 137px,
      #ebf3f9 1px,
      #ebf3f9 100%
    );
    background-image: -o-linear-gradient(
      left,
      #428bca 137px,
      #ebf3f9 1px,
      #ebf3f9 100%
    );
    background-image: -ms-linear-gradient(
      left,
      #428bca 137px,
      #ebf3f9 1px,
      #ebf3f9 100%
    );
    background-image: linear-gradient(
      left,
      #428bca 137px,
      #ebf3f9 1px,
      #ebf3f9 100%
    );
  }
}
